import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Modal from 'react-modal';
import { getNotifications, cancelNotification, cancel } from '../services/notifications';

Modal.setAppElement(document.getElementById('root'))

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

class Notifications extends Component {
    _isMounted = true;
    columns = [];

    constructor(props) {
        super(props);

        this.state = { notifications: [], page: 1, pages: 1, show: 10, loading: false, modalIsOpen: false, image: '', detailedContent: {} };

        this.fetchData = this.fetchData.bind(this);
        this.generateColumns = this.generateColumns.bind(this);
        this._cancel = this._cancel.bind(this);
    }

    async fetchData(state, instance) {
        if (this._isMounted) {
            this.setState({loading: true});
        }

        let options = {};

        if (state) {
            options = {
                sort: state.sorted.length > 0 ? state.sorted[0].id : 'start_date',
                direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
                filters: state.filtered.length > 0 ? state.filtered : [],
                results: state.pageSize,
                offset: (state.page +1) * state.pageSize - state.pageSize
            };
        } else {
            options = {
                sort: 'start_date',
                direction: 'desc',
                filters: [],
                results: 10,
                offset: 0
            }
        }
        

        try {
            let data = await getNotifications(options);
            if (this._isMounted) {
                this.setState({notifications: data.notifications, loading: false, pages: data.pages, show: data.results, lastUpdate: data.last_update});
            }
        } catch (e) {

        }
    }

    generateColumns() {
        let columns = [
            {
                Header: <span>Name <i className="fas fa-sort" /></span>,
                accessor: 'name',
                minWidth: 120,
                filterable: true
            },
            {
                id: 'title',
                Header: <span>Title <i className="fas fa-sort" /></span>,
                accessor: (d) => <span dangerouslySetInnerHTML={{ __html: d.title }}></span>,
                minWidth: 140,
                filterable: true
            },
            {
                id: 'message',
                Header: <span>Message <i className="fas fa-sort" /></span>,
                accessor: (d) => <span dangerouslySetInnerHTML={{ __html: d.message }}></span>,
                minWidth: 200,
                filterable: true
            },
            {
                id: 'start_date',
                Header: <span>Send Date <i className="fas fa-sort" /></span>,
                accessor: (d) => new Date(d.start_date).toLocaleString('es-ar')
            },
            {
                Header: <span>Recipients <i className="fas fa-sort" /></span>,
                accessor: 'recipients',
                width: 120,
                sortable: true
            },
            {
                id: 'converted_percentage',
                Header: <span>Opened <i className="fas fa-sort" /></span>,
                accessor: (d) => <span>{parseFloat(Math.round(d.converted_percentage * 100) / 100).toFixed(2)}%</span>,
                width: 100,
                sortable: true
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'status',
                sortable: false
            },
            {
                id: 'actions',
                Header: 'Actions',
                accessor: d => ((!d.canceled && !d.cancelling && !d.creating) && d.status !== 'Sent') ? <button id={d._id} onClick={this._cancel} className="btn-sm btn-icon btn-icon-left btn-danger no-border"><i className="ico fas fa-ban" /> Cancel</button> : null,
                resizable: false,
                width: 90,
                sortable: false
            }
        ];

        return columns;
    }

    async _cancel(event) {
        let {id} = event.target;
        let cancellation = await cancelNotification(id);
        if (cancellation) {
            this.fetchData();
        } 
    };

    componentWillMount() {
        this.columns = this.generateColumns();
    }

    componentWillUnmount() {
        this._isMounted = false;
        cancel();
    }

    render() {
        return (
            <section>
                <div className='row'>
                    <div className='col-md-6 col-xs-12 align-self-center'>
                        <h3>Notifications</h3>
                    </div>
                    <div className='col-md-6 col-xs-12 text-right'>
                        <div className='row justify-content-md-end'>
                            <div className='col-md-6 col-xs-12'>
                                <NavLink to='/notifications/new' className="btn btn-icon btn-icon-left btn-success"><i className="ico fas fa-plus" />Create Notification</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <ReactTable
                    data={this.state.notifications}
                    columns={this.columns}
                    pages={this.state.pages}
                    defaultPageSize={this.state.show}
                    loading={this.state.loading}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageJump={true}
                    resizable={true}
                    sortable={true}
                    manual
                    onFetchData={this.fetchData}
                    onSortedChange={cancel}
                    onFilteredChange={cancel}
                    className="-striped -highlight"
                />
                {this.state.lastUpdate ? <small>Last Update: {new Date(this.state.lastUpdate).toLocaleString('es-ar')}</small> : null}
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                    <h4 dangerouslySetInnerHTML={{ __html: this.state.detailedContent.name }}></h4>
                    <img src={this.state.image} alt={this.state.detailedContent.name} className="modal-content-image" />
                </Modal>
            </section>
        );
  };
}

export default Notifications;

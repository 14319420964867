import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Modal from 'react-modal';
import { getPayments, cancel } from '../services/payments';

Modal.setAppElement(document.getElementById('root'))

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

class Payments extends Component {
    _isMounted = true;
    columns = [];

    constructor(props) {
        super(props);

        this.state = { payments: [], page: 1, pages: 1, show: 10, loading: false, modalIsOpen: false, image: '', detailedContent: {} };

        this.fetchData = this.fetchData.bind(this);
        this.generateColumns = this.generateColumns.bind(this);
    }

    async fetchData(state, instance) {
        if (this._isMounted) {
            this.setState({loading: true});
        }

        let options = {};

        if (state) {
            options = {
                sort: state.sorted.length > 0 ? state.sorted[0].id : 'start_date',
                direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
                filters: state.filtered.length > 0 ? state.filtered : [],
                results: state.pageSize,
                offset: (state.page +1) * state.pageSize - state.pageSize
            };
        } else {
            options = {
                sort: 'start_date',
                direction: 'desc',
                filters: [],
                results: 10,
                offset: 0
            }
        }
        

        try {
            let data = await getPayments(options);
            if (this._isMounted) {
                this.setState({payments: data.payments, loading: false, pages: data.pages, show: data.results, lastUpdate: data.last_update});
            }
        } catch (e) {

        }
    }

    generateColumns() {
        let columns = [
            {
                id: 'client',
                Header: 'Client Name',
                accessor: d => d.client ? `${d.client.fname} ${d.client.lname}` : null,
                sortable: false
            },
            {
                id: 'client_dni',
                Header: 'Client DNI',
                accessor: d => d.client ? `${d.client.dni}` : null,
                sortable: false
            },
            {
                Header: <span>Operation Id <i className="fas fa-sort" /></span>,
                accessor: 'operationId'
            },
            {
                Header: <span>Method <i className="fas fa-sort" /></span>,
                accessor: 'method'
            },
            {
                Header: <span>Amount <i className="fas fa-sort" /></span>,
                accessor: 'amount'
            },
            {
                Header: <span>Type <i className="fas fa-sort" /></span>,
                accessor: 'type'
            },
            {
                id: 'createdAt',
                Header: <span>Date <i className="fas fa-sort" /></span>,
                accessor: (d) => new Date(d.createdAt).toLocaleString('es-ar')
            },
            {
                id: 'products',
                Header: 'Products',
                accessor: d => d.purchase ? `${d.purchase.products.length}` : 0,
                sortable: false
            },
            {
                id: 'paymentProcessed',
                Header: <span>Processed <i className="fas fa-sort" /></span>,
                accessor: d => d.paymentProcessed ? 'Yes' : 'No'
            },
            /*{
                id: 'actions',
                Header: 'Actions',
                accessor: d => ((!d.canceled && !d.cancelling && !d.creating) && d.status !== 'Sent') ? <button id={d._id} className="btn-sm btn-icon btn-icon-left btn-danger no-border"><i className="ico fas fa-ban" /> Cancel</button> : null,
                resizable: false,
                width: 90,
                sortable: false
            }*/
        ];

        return columns;
    }

    componentWillMount() {
        this.columns = this.generateColumns();
    }

    componentWillUnmount() {
        this._isMounted = false;
        cancel();
    }

    render() {
        return (
            <section>
                <div className='row'>
                    <div className='col-md-6 col-xs-12 align-self-center'>
                        <h3>Payments</h3>
                    </div>
                </div>
                <hr />
                <ReactTable
                    data={this.state.payments}
                    columns={this.columns}
                    pages={this.state.pages}
                    defaultPageSize={this.state.show}
                    loading={this.state.loading}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageJump={true}
                    resizable={true}
                    sortable={true}
                    manual
                    onFetchData={this.fetchData}
                    onSortedChange={cancel}
                    onFilteredChange={cancel}
                    className="-striped -highlight"
                />
                {this.state.lastUpdate ? <small>Last Update: {new Date(this.state.lastUpdate).toLocaleString('es-ar')}</small> : null}
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                    <h4 dangerouslySetInnerHTML={{ __html: this.state.detailedContent.name }}></h4>
                    <img src={this.state.image} alt={this.state.detailedContent.name} className="modal-content-image" />
                </Modal>
            </section>
        );
  };
}

export default Payments;
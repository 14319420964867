import React, { Component } from 'react';
import swal from 'sweetalert';
import { get, changeContactInfo } from '../services/status';


class ContactInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            info: {
                phone_number: {number: '', string: ''},
                whatsapp_accesories: {number: '', string: ''},
                whatsapp_contact: {number: '', string: ''},
                whatsapp_contact_alt: {number: '', string: ''},
                whatsapp_sales: {number: '', string: ''},
                whatsapp_financing: {number: '', string: ''},
                whatsapp_insurance: {number: '', string: ''}
            },
            phone_number: {number: '', string: ''},
            whatsapp_accesories: {number: '', string: ''},
            whatsapp_contact: {number: '', string: ''},
            whatsapp_contact_alt: {number: '', string: ''},
            whatsapp_sales: {number: '', string: ''},
            whatsapp_financing: {number: '', string: ''},
            whatsapp_insurance: {number: '', string: ''}
         };

        this.discard = this.discard.bind(this);
        this.submit = this.submit.bind(this);
        this.handleInputChanges = this.handleInputChanges.bind(this);
        this.input = React.createRef();
    }

    async componentDidMount() {
        let info = await get();
        this.setState({
            info,
            changes: false,
            phone_number: info.phone_number,
            whatsapp_accesories: info.whatsapp_accesories,
            whatsapp_contact: info.whatsapp_contact,
            whatsapp_contact_alt: info.whatsapp_contact_alt,
            whatsapp_sales: info.whatsapp_sales,
            whatsapp_financing: info.whatsapp_financing,
            whatsapp_insurance: info.whatsapp_insurance
        });
    }

    discard() {
        swal({   
            title: "Discard?",   
            text: "Are you sure you want to discard the changes you made?",   
            icon: "warning",  
            buttons: ['No, keep the changes.', 'Yes, delete them.'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            (value) => {
                if (value) {
                    const { phone_number, whatsapp_contact, whatsapp_contact_alt, whatsapp_financing, whatsapp_insurance, whatsapp_sales } = this.state.info;
                    this.setState({
                        changes: false,
                        phone_number,
                        whatsapp_contact,
                        whatsapp_contact_alt,
                        whatsapp_financing,
                        whatsapp_insurance,
                        whatsapp_sales
                    });
                }
            }
        );
    }

    async submit(event) {
        event.preventDefault();

        const { phone_number, whatsapp_contact, whatsapp_contact_alt, whatsapp_financing, whatsapp_insurance, whatsapp_sales } = this.state;

        const data = {phone_number, whatsapp_contact, whatsapp_contact_alt, whatsapp_financing, whatsapp_insurance, whatsapp_sales};

        await changeContactInfo(data);
        try {
            swal({   
                title: "Success",   
                text: "The changes are now live.",   
                icon: "success"
            })
            this.setState({info: data, changes: false})
        } catch (e) {
            console.log(e);
            this.setState({error: "There is a problem. Please, try again later."})
        }
    }

    handleInputChanges(event) {
        let { id, value } = event.target;

        switch(id) {
            case 'phone_number_number':
                this.setState({ phone_number: {...this.state.phone_number, number: value} });
                break;
            case 'phone_number_string':
                this.setState({ phone_number: {...this.state.phone_number, string: value} });
                break;
            case 'whatsapp_contact_number':
                this.setState({ whatsapp_contact: {...this.state.whatsapp_contact, number: value} });
                break;
            case 'whatsapp_contact_string':
                this.setState({ whatsapp_contact: {...this.state.whatsapp_contact, string: value} });
                break;
            case 'whatsapp_contact_alt_number':
                this.setState({ whatsapp_contact_alt: {...this.state.whatsapp_contact_alt, number: value} });
                break;
            case 'whatsapp_contact_alt_string':
                this.setState({ whatsapp_contact_alt: {...this.state.whatsapp_contact_alt, string: value} });
                break;
            case 'whatsapp_sales_number':
                this.setState({ whatsapp_sales: {...this.state.whatsapp_sales, number: value} });
                break;
            case 'whatsapp_sales_string':
                this.setState({ whatsapp_sales: {...this.state.whatsapp_sales, string: value} });
                break;
            case 'whatsapp_insurance_number':
                this.setState({ whatsapp_insurance: {...this.state.whatsapp_insurance, number: value} });
                break;
            case 'whatsapp_insurance_string':
                this.setState({ whatsapp_insurance: {...this.state.whatsapp_insurance, string: value} });
                break;
            case 'whatsapp_financing_number':
                this.setState({ whatsapp_financing: {...this.state.whatsapp_financing, number: value} });
                break;
            case 'whatsapp_financing_string':
                this.setState({ whatsapp_financing: {...this.state.whatsapp_financing, string: value} });
                break;
            default:
                break;
        }

        this.setState({ changes: true });
    }

    render() {
        return (
            <div className="row small-spacing">
                <div className="col-lg-12 col-xs-12">
                    <form onSubmit={this.submit}>
                        <div className="box-content card white">
                            <h4 className="box-title">Teléfono de Contacto</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.phone_number.number !== this.state.phone_number.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="name">Número (Ej. 08104445555)</label>
                                            <input type="text" className="form-control" id="phone_number_number" placeholder="Number" ref={this.input} value={this.state.phone_number.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.phone_number.string !== this.state.phone_number.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="destination">Número formateado (Ej. 0810 444 5555)</label>
                                            <input type="text" className="form-control" id="phone_number_string" placeholder="Formatted Number" ref={this.input} value={this.state.phone_number.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-content card white">
                            <h4 className="box-title">WhatsApp de Contacto</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_contact.number !== this.state.whatsapp_contact.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="name">Número (Ej. 1153064284)</label>
                                            <input type="text" className="form-control" id="whatsapp_contact_number" placeholder="Number" ref={this.input} value={this.state.whatsapp_contact.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_contact.string !== this.state.whatsapp_contact.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="destination">Número formateado (Ej. +54 911 5306 4284)</label>
                                            <input type="text" className="form-control" id="whatsapp_contact_string" placeholder="Formatted Number" ref={this.input} value={this.state.whatsapp_contact.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-content card white">
                            <h4 className="box-title">WhatsApp de Contacto Alternativo</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_contact_alt.number !== this.state.whatsapp_contact_alt.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="name">Número (Ej. 1153064284)</label>
                                            <input type="text" className="form-control" id="whatsapp_contact_alt_number" placeholder="Number" ref={this.input} value={this.state.whatsapp_contact_alt.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_contact_alt.string !== this.state.whatsapp_contact_alt.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="destination">Número formateado (Ej. +54 911 5306 4284)</label>
                                            <input type="text" className="form-control" id="whatsapp_contact_alt_string" placeholder="Formatted Number" ref={this.input} value={this.state.whatsapp_contact_alt.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-content card white">
                            <h4 className="box-title">WhatsApp de Accesorios</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_accesories.number !== this.state.whatsapp_accesories.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="whatsapp_accesories_number">Número (Ej. 1153064284)</label>
                                            <input type="text" className="form-control" id="whatsapp_accesories_number" placeholder="Number" ref={this.input} value={this.state.whatsapp_accesories.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_accesories.string !== this.state.whatsapp_accesories.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="whatsapp_accesories_string">Número formateado (Ej. +54 911 5306 4284)</label>
                                            <input type="text" className="form-control" id="whatsapp_accesories_string" placeholder="Formatted Number" ref={this.input} value={this.state.whatsapp_accesories.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-content card white">
                            <h4 className="box-title">WhatsApp de Reservas</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_sales.number !== this.state.whatsapp_sales.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="name">Número (Ej. 1153064284)</label>
                                            <input type="text" className="form-control" id="whatsapp_sales_number" placeholder="Number" ref={this.input} value={this.state.whatsapp_sales.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_sales.string !== this.state.whatsapp_sales.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="destination">Número formateado (Ej. +54 911 5306 4284)</label>
                                            <input type="text" className="form-control" id="whatsapp_sales_string" placeholder="Formatted Number" ref={this.input} value={this.state.whatsapp_sales.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-content card white">
                            <h4 className="box-title">WhatsApp de Seguros</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_insurance.number !== this.state.whatsapp_insurance.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="name">Número (Ej. 1153064284)</label>
                                            <input type="text" className="form-control" id="whatsapp_insurance_number" placeholder="Number" ref={this.input} value={this.state.whatsapp_insurance.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_insurance.string !== this.state.whatsapp_insurance.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="destination">Número formateado (Ej. +54 911 5306 4284)</label>
                                            <input type="text" className="form-control" id="whatsapp_insurance_string" placeholder="Formatted Number" ref={this.input} value={this.state.whatsapp_insurance.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-content card white">
                            <h4 className="box-title">WhatsApp de Financiación</h4>
                            <div className="card-content">
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_financing.number !== this.state.whatsapp_financing.number ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="name">Número (Ej. 1153064284)</label>
                                            <input type="text" className="form-control" id="whatsapp_financing_number" placeholder="Number" ref={this.input} value={this.state.whatsapp_financing.number} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.info.whatsapp_financing.string !== this.state.whatsapp_financing.string ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="destination">Número formateado (Ej. +54 911 5306 4284)</label>
                                            <input type="text" className="form-control" id="whatsapp_financing_string" placeholder="Formatted Number" ref={this.input} value={this.state.whatsapp_financing.string} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-xs-12 margin-top-20">
                                <p className="danger">{this.state.error}</p>
                            </div>
                            <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } disabled={!this.state.changes} onClick={this.discard}>Discard</button>
                                <button type="submit" className="btn btn-primary btn-md" disabled={!this.state.changes}>Save Changes</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
  };
}

export default ContactInfo;
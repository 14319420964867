import React, { Component } from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import swal from 'sweetalert';
import { getSubscribers, exportSubscribers } from '../services/subscribers';

class Subscribers extends Component {

    constructor(props) {
        super(props);

        this.state = { subscribers: [], page: 1, pages: 1, show: 10, loading: false };

        this.export = this.export.bind(this);
    }

    async export() {
        let req = await exportSubscribers();

        if (req.success) {
            swal({   
                title: "Data it's on its way!",   
                text: "We are preparing the data. You will receive an email shortly.",   
                icon: "success",
                buttons: { confirm: { value: 'OK', className: 'btn btn-primary' } },
                className: 'logout-alert'
            });
        } else {
            swal({   
                title: "Ups, we can't provide that data.",   
                text: "There is an error on your request. Please try again later or contact us.",   
                icon: "error",
                buttons: { confirm: { value: 'OK', className: 'btn btn-danger' } },
                className: 'logout-alert'
            });
        }
    }

    render() {

        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'><h3>Subscribers</h3></div>
                    <div className='col-md-6 text-right'><button onClick={this.export} className="btn btn-icon btn-icon-left btn-success"><i className="ico fa fa-save" /> Export Subscribers</button></div>
                </div>
                <hr />
                <ReactTable
                    data={this.state.slides}
                    columns={[
                        {
                            Header: "Email",
                            accessor: 'email',
                            width: 400,
                        },
                        {
                            id: "createdAt",
                            Header: "Register Date",
                            accessor: (d) => new Date(d.createdAt).toLocaleString(),
                            width: 200
                        }
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={this.state.show}
                    loading={this.state.loading}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageJump={true}
                    resizable={true}
                    sortable={false}
                    manual
                    onFetchData={
                        async (state, instance) => {
                            this.setState({loading: true});
                            let subscribers = await getSubscribers(state.pageSize, (state.page +1) * state.pageSize - state.pageSize);
                            this.setState({slides: subscribers.subscribers, loading: false, pages: subscribers.pages, show: subscribers.results});
                        }
                    }
                className="-striped -highlight"
                />
            </div>
        );
  };
}

export default Subscribers;

import React, { Component } from 'react';
import { Line } from "react-chartjs-2";
import { getDashboard } from '../services/dashboard';

let createDataline = (labels, data, label, color) => {

    return {
        labels: labels || [],
        datasets: [
            {
                label: label || 'waiting',
                fill: false,
                lineTension: 0.1,
                backgroundColor: color || "#00aeff",
                borderColor: color || "#00aeff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: color || "#00aeff",
                pointBackgroundColor: color || "#00aeff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: color || "#00aeff",
                pointHoverBorderColor: color || "#00aeff",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: data || []
            }
        ]
    }
};

let createDoubleDataline = (labels, data_a, data_b, label_a, label_b, color_a, color_b) => {

    return {
        labels: labels || [],
        datasets: [
            {
                label: label_a || 'waiting',
                fill: false,
                lineTension: 0.1,
                backgroundColor: color_a || "rgba(75,192,192,0.4)",
                borderColor: color_a || "rgba(75,192,192,1)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: color_a || "rgba(75,192,192,0.4)",
                pointBackgroundColor: color_a || "rgba(75,192,192,0.4)",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: color_a || "rgba(75,192,192,0.4)",
                pointHoverBorderColor: color_a || "rgba(75,192,192,0.4)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: data_a || []
            },
            {
                label: label_b || 'waiting',
                fill: false,
                lineTension: 0.1,
                backgroundColor: color_b || "#00aeff",
                borderColor: color_b || "#00aeff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: color_b || "#00aeff",
                pointBackgroundColor: color_b || "#00aeff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: color_b || "#00aeff",
                pointHoverBorderColor: color_b || "#00aeff",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: data_b || []
            }
        ]
    }
};

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = { purchases: createDataline(), credicuotas: createDataline(), paymentsAmount: 0, clients: 0, searches: 0, payments: 0 }
    }

    async componentDidMount() {
        let data = await getDashboard();
        let purchases = createDataline(data.purchases.labels, data.purchases.data, 'Carts');
        let credicuotas = createDoubleDataline(data.credicuotasApproved.labels, data.credicuotasApproved.data, data.credicuotasRejected.data, 'Approved', 'Rejected', '#00ce55', '#ff3838');
        let paymentsAmount = data.paymentsAmount;
        let clients = data.clients;
        let searches = data.searches;
        let payments = data.payments;
        this.setState({purchases, credicuotas, paymentsAmount, clients, searches, payments});
    }

    render() {

        return (
            <div className='container-fluid'>
                <div className='row mt-3 mb-5'>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="box-content bg-primary">
                            <div className="statistics-box with-icon">
                                <i className="ico fas fa-user-tag text-inverse text-white"></i>
                                <h2 className="counter text-inverse text-white">{this.state.clients}</h2>
                                <p className="text text-white">Clients</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="box-content bg-warning">
                            <div className="statistics-box with-icon">
                                <i className="ico fas fa-search text-inverse text-white"></i>
                                <h2 className="counter text-inverse text-white">{this.state.searches}</h2>
                                <p className="text text-white">Searches</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="box-content bg-violet">
                            <div className="statistics-box with-icon">
                                <i className="ico fas fa-money-check-alt text-inverse text-white"></i>
                                <h2 className="counter text-inverse text-white">{this.state.payments}</h2>
                                <p className="text text-white">Successful Payments</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="box-content bg-success">
                            <div className="statistics-box with-icon">
                                <i className="ico fas fa-dollar-sign text-inverse text-white"></i>
                                <h2 className="counter text-inverse text-white">${this.state.paymentsAmount}</h2>
                                <p className="text text-white">Payments Amount</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-6 col-sm-12 mb-5'>
                        <div className='box-content'>
                            <h4 className='box-title'>Started Carts</h4>
                            <Line data={this.state.purchases} options={{ responsive: true }} />
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 mb-5'>
                        <div className='box-content'>
                            <h4 className='box-title'>Credicuotas</h4>
                            <Line data={this.state.credicuotas} options={{ responsive: true }} />
                        </div>
                    </div>
                </div>
                
            </div>
            
        )
    }
}

export default Dashboard;
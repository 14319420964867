import axios from 'axios';
import { API_URL } from '../utils/config';
import loginStore from '../reducers/auth';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const getPayments = async (options) => {

    const { sort, direction, filters, status, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
        status
    };

    const url = `${API_URL}/admin/payments/getAll?results=${results}&offset=${offset}`;

    try {
        let request = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                loginStore.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};
import axios from 'axios';
import { API_URL } from '../utils/config';
import loginStore from '../reducers/auth';
import loader from '../reducers/loading';

const expired = 'TokenExpiredError';

export function getDashboard(results, offset) {
    loader.dispatch({ type: 'LOADING' });
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/admin/dashboard`)
            .then(
                res => {
                    loader.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    if (err.response.data.error === expired) {
                        loginStore.dispatch({ type: 'LOGOUT' });
                    }
                    loader.dispatch({ type: 'LOADED' });
                    reject(err.response.data);
                }
            );
    }) 
}
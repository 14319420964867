import React, { Component } from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Modal from 'react-modal';
import { DateRangePicker } from 'react-date-range';

import { analytics, cancel } from '../services/search.js';

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

class Search extends Component {

    constructor(props) {
        super(props);

        let today = new Date();

        this.state = {
            dataset: [],
            loading: true,
            search: '',
            startDate: new Date(today.getFullYear(), today.getMonth() -1, today.getDate()),
            endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
            modalIsOpen: false,
            totalSearches: 0
        };

        this.applyFilter = this.applyFilter.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async componentDidMount() {
        let options = {
            to: this.state.endDate.toLocaleDateString(),
            from: this.state.startDate.toLocaleDateString()
        }

        try {
            let data = await analytics(options);
            console.log(data);
            if (data.dataset) {
                this.setState({ dataset: data.dataset, loading: false, totalSearches: data.totalSearches});
            }
        } catch(e) {
            console.error(e);
        }
    }

    componentWillUnmount() {
        cancel();
    }

    handleSelect(ranges){
        this.setState({ nextStartDate: ranges.selection.startDate, nextEndDate: ranges.selection.endDate });
    }
    
    openModal() {
        this.setState({ modalIsOpen: true, nextStartDate: this.state.startDate, nextEndDate: this.state.endDate });
    }

    closeModal() {
        this.setState({ modalIsOpen: false, nextEndDate: this.state.endDate, nextStartDate: this.state.startDate });
    }

    async applyFilter() {
        cancel();
        this.setState({ modalIsOpen: false, startDate: this.state.nextStartDate, endDate: this.state.nextEndDate, loading: true });

        let options = {
            to: this.state.endDate.toLocaleDateString(),
            from: this.state.startDate.toLocaleDateString()
        }

        let data = await analytics(options);

        if (data.dataset) {
            this.setState({ dataset: data.dataset, loading: false, totalSearches: data.totalSearches});
        }
    }

    render() {
        
        let selectionRange = {
            startDate: this.state.nextStartDate,
            endDate: this.state.nextEndDate,
            key: 'selection',
        };

        return (
            <section>
                <div className='row'>
                    <div className='col-md-6 col-xs-12 align-self-center'><h3>Searches</h3></div>
                    <div className='col-md-6 col-xs-12 text-right'>
                        <button className="btn btn-primary btn-icon btn-icon-left" onClick={this.openModal}>
                            <i className="ico far fa-calendar-alt"></i>
                            {this.state.startDate.toLocaleDateString('es-ar')} - {this.state.endDate.toLocaleDateString('es-ar')}
                        </button>
                    </div>
                </div>
                <hr />
                <ReactTable
                    data={this.state.dataset}
                    columns={[
                        {
                            id: '_id',
                            Header: "Query",
                            accessor: (d) => d._id,
                            Footer: <span><b>Terms Searched:</b> {this.state.dataset.length}</span>,
                            filterMethod: (filter, row, column) => {
                                const { id, value } = filter;
                                const pattern = new RegExp(value, 'gi');
                                const result = row[id] !== undefined ? pattern.test(row[id]) : true;
                                return result;
                            },
                        },
                        {
                            Header: "Searches",
                            accessor: 'count',
                            filterable: false,
                            Footer: <span><b>Total Searches:</b> {this.state.totalSearches}</span>
                        }
                    ]}
                    defaultPageSize={10}
                    loading={this.state.loading}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageJump={true}
                    resizable={true}
                    sortable={true}
                    filterable={true}
                    className="-striped -highlight"
                />
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="row justify-content-center align-items-center">
                        <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={this.handleSelect}
                        />
                    </div>
                    <hr />
                    <div className="row justify-content-center align-items-center">
                        <div className="col-6 text-center">
                            <button className="btn btn-danger" onClick={this.closeModal}>Cancel</button>
                        </div>
                        <div className="col-6 text-center">
                            <button className="btn btn-primary" onClick={this.applyFilter}>Apply Filter</button>
                        </div>
                    </div>
                </Modal>
            </section>
        );
  };
}

export default Search;

import React, { Component } from 'react';
import "react-table/react-table.css";
import { get, change } from '../services/status';

class Status extends Component {

    constructor(props) {
        super(props);

        this.state = { status: false, initialState: false };
        this.handleInputChanges = this.handleInputChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    async componentDidMount() {
        let status = await get();
        this.setState({status: status.active, initialState: status.active, last_update: status.updatedAt});
    }

    handleInputChanges(event) {
        let status = event.target.checked;
        this.setState({ status });
    }

    async saveChanges() {
        let status = await change(this.state.status);
        this.setState({initialState: status.active, last_update: status.updatedAt});
    }

    render() {
        return (
            <div>
                <div className='row'>
                    <div className='col-12'>
                        <h3>Status</h3>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-12'>
                        <div className="switch warning">
                            <input type="checkbox" id="status" onChange={this.handleInputChanges} checked={this.state.status} />
                            <label htmlFor="status">{ this.state.status ? 'Activated' : 'Deactivated' }</label>
                        </div>
                        <button className="btn btn-primary" onClick={this.saveChanges} disabled={this.state.status === this.state.initialState}>Save Changes</button>
                        {
                            this.state.last_update
                            ? <p className="mt-3">Last Update: {new Date(this.state.last_update).toLocaleDateString('es-AR')} {new Date(this.state.last_update).toLocaleTimeString('es-AR')}</p>
                            : null
                        }
                    </div>
                </div>
                
            </div>
        );
  };
}

export default Status;

import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import swal from 'sweetalert';
import { createNotification } from '../services/notifications';

const maxDate = () => {
    let today = new Date();
    return today.setDate(today.getDate() + 30);
}


class NotificationsNew extends Component {
    constructor(props) {
        super(props);

        this.state = { name: '', title: '', message: '', url: '', start_date: '', intelligentDelivery: false };

        this.discard = this.discard.bind(this);
        this.submit = this.submit.bind(this);
        this.handleInputChanges = this.handleInputChanges.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleIntelligentDeliveryChange = this.handleIntelligentDeliveryChange.bind(this);
        this.input = React.createRef();
    }

    async componentDidMount() {
        this.setState({ active: false, priority: 1, changes: false });
    }

    discard() {
        if (this.state.changes) {
            swal({   
                title: "Discard?",   
                text: "Are you sure you want to discard the changes you made?",   
                icon: "warning",  
                buttons: ['No, keep the changes.', 'Yes, delete them.'], 
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            }).then(
                (value) => {
                    if (value) {
                        this.props.history.push('/notifications');
                    }
                }
            );
        } else {
            this.props.history.push('/notifications');
        }
        
    }

    async submit(event) {
        event.preventDefault();

        let { name, title, message, url, start_date, intelligentDelivery } = this.state;

        if (!name || !title || !message) {
            this.setState({error: "Please, complete all the fields"});
            return;
        }

        if (url && url.indexOf('http') !== 0) {
            this.setState({error: "Please, enter a valid URL."});
            return;
        }

        let newNotification = {
            name,
            title,
            contents: message,
            url,
            datetime: start_date,
            intelligentDelivery
        };

        try {
            const create = await createNotification(newNotification);
            create.datetime = new Date(create.datetime);

            let { name, title, message, url, datetime, intelligentDelivery } = create.data;

            this.setState({ changes: false, name, title, message, start_date: datetime, url, intelligentDelivery, error: null });
            
            swal({   
                title: "Success",   
                text: "The notification has been created.",   
                icon: "success"
            }).then(
                (value) => {
                    this.props.history.push('/notifications');
                }
            );
        } catch (e) {
            console.log(e);
            this.setState({error: "There is a problem. Please, try again later."})
        }
    }

    handleInputChanges(event) {
        let { id, value } = event.target;

        switch(id) {
            case 'name':
                this.setState({ name: value });
                break;
            case 'url':
                this.setState({ url: value });
                break;
            case 'title':
                this.setState({ title: value });
                break;
            case 'message':
                this.setState({ message: value });
                break;
            default:
                break;
        }

        this.setState({ changes: true });
    }

    handleStartChange(date) {
        this.setState({ 
            start_date: date,
            changes: true
        });
    }

    handleIntelligentDeliveryChange(evt) {
        let { value } = evt.target;

        this.setState({
            intelligentDelivery: value === 'yes' ? true : false,
            changes: true
        })
    }

    render() {

        return (
            <div className="row small-spacing">
                <div className="col-lg-12 col-xs-12">
                    <div className="box-content card white">
                        <h4 className="box-title">New Notification</h4>
                        <div className="card-content">
                            <form onSubmit={this.submit}>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Name" ref={this.input} defaultValue={this.state.name} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="destination">Destination</label>
                                            <input type="text" className="form-control" id="destination" placeholder="Enter an URL (optional)" ref={this.input} defaultValue={this.state.destination} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="name">Title</label>
                                            <input type="text" className="form-control" id="title" placeholder="Enter a Title" ref={this.input} defaultValue={this.state.title} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="destination">Message</label>
                                            <input type="text" className="form-control" id="message" placeholder="Enter a Message" ref={this.input} defaultValue={this.state.message} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="start_date">Send Date</label>
                                            <div className="form-control">
                                            <DatePicker
                                                id="start_date"
                                                className="datepick"
                                                selected={this.state.start_date}
                                                onChange={this.handleStartChange}
                                                showTimeSelect
                                                dateFormat="dd/MM/yyyy - HH:mm"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                timeCaption="time"
                                                minDate={new Date()}
                                                maxDate={maxDate()}
                                                placeholderText="Enter a Send Date"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="active">Intelligent Delivery</label>
                                            <select id="active" className="form-control" onChange={this.handleIntelligentDeliveryChange} value={this.state.intelligentDelivery ? 'yes' : 'no'}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 margin-top-20">
                                        <p className="danger">{this.state.error}</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                        <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={this.discard}>Discard</button>
                                        <button type="submit" className="btn btn-primary btn-md" disabled={!this.state.changes}>Create Notification</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
  };
}

export default NotificationsNew;

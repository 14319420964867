import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import loginStore from '../reducers/auth';
import $ from 'jquery';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        
        this.state = loginStore.getState();
    }
    
    componentDidMount() {
        $(".js__accordion").each(function(){
            var selector = $(this);
            selector.find(".js__control").on("click",function(event){
                event.preventDefault();
                if ($(this).parent().hasClass("active")){
                    $(this).parent().removeClass("active");
                    $(this).next().stop().slideUp(400);
                }else{
                    selector.find(".active").children(".js__content").stop().slideUp(400);
                    selector.find(".active").removeClass("active");
                    $(this).parent().addClass("active");
                    $(this).next(".js__content").slideDown(400);
                }
            });
        });

        $(".js__menu_mobile").on("click",function(){
            $("html").toggleClass("menu-active");
            $(window).trigger("resize");
        });
        
        $(".js__menu_close").on("click",function(){
            $("html").removeClass("menu-active");
        });

        $("body").on("click",function(event){
            if ($("html.menu-active").length && $(window).width() < 800){
                var selector = $(event.target);
                if (!(selector.hasClass("main-menu") || selector.hasClass("js__menu_mobile") || selector.parents(".main-menu").length || selector.parents(".js__menu_mobile").length)){
                    $("html").removeClass("menu-active");
                }
            }
        });
    }

    render() {
        return (
            <div>
                <div className="main-menu">
                    <header className="header">
                        <a href="index.html" className="logo">Motonet EC</a>
                        <button type="button" className="button-close fa fa-times js__menu_close"></button>
                        <div className="user">
                            <NavLink to="/profile" className="avatar"><img src='./assets/images/profile.png' alt="Profile" /><span className="status online"></span></NavLink>
                            <h5 className="name"><NavLink to="/profile">{this.state.user && this.state.user.name}</NavLink></h5>
                            <h5 className="position">Motonet</h5>
                        </div>
                    </header>
                    <div className="content">
                        <div className="navigation">
                            <h5 className="title">Application</h5>
                            <ul className="menu js__accordion">
                                <li>
                                    <NavLink to="/" exact activeClassName="current"><i className="menu-icon fa fa-home"></i><span>Dashboard</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/credicuotas" activeClassName="current"><i className="menu-icon fa fa-hand-holding-usd"></i><span>Credicuotas</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/insurance" activeClassName="current"><i className="menu-icon fa fa-user-shield"></i><span>Insurance</span></NavLink>
                                </li>
                                <li>
                                    <a className="parent-item js__control" href="#"><i className="menu-icon fa fa-bell"></i><span>Notifications</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                    <ul className="sub-menu js__content">
                                        <NavLink to="/notifications/new" exact>Create New Notification</NavLink>
                                        <NavLink to="/notifications" exact>View All</NavLink>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to="/searches" activeClassName="current"><i className="menu-icon fa fa-search"></i><span>Searches</span></NavLink>
                                </li>
                                <li>
                                    <a className="parent-item js__control" href="#"><i className="menu-icon fa fa-sticky-note"></i><span>Slides</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                    <ul className="sub-menu js__content">
                                        <NavLink to="/slides/new" exact>Create New Slide</NavLink>
                                        <NavLink to="/slides" exact>View All</NavLink>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to="/subscribers" activeClassName="current"><i className="menu-icon fa fa-users"></i><span>Subscribers</span></NavLink>
                                </li>
                            </ul>
                            <h5 className="title">Purchases</h5>
                            <ul className="menu js__accordion">
                                <li>
                                    <NavLink to="/carts" exact activeClassName="current"><i className="menu-icon fas fa-shopping-cart"></i><span>Carts</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/payments" exact activeClassName="current"><i className="menu-icon fas fa-cash-register"></i><span>Payments</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/clients" exact activeClassName="current"><i className="menu-icon fas fa-address-card"></i><span>Clients</span></NavLink>
                                </li>
                            </ul>
                            <h5 className="title">Configuration</h5>
                            <ul className="menu js__accordion">
                                <li>
                                    <NavLink to="/status" exact activeClassName="current"><i className="menu-icon fas fa-toggle-on"></i><span>EC Status</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact-info" exact activeClassName="current"><i className="menu-icon fas fa-phone"></i><span>Contact Information</span></NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;
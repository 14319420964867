import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import { API_URL } from '../utils/config';
import loginStore from '../reducers/auth';
import loader from '../reducers/loading';

const expired = 'TokenExpiredError';

export function getSlides(results, offset) {

    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/admin/slides/list?results=${results}&offset=${offset}`)
            .then(
                res => {
                    resolve(res.data);
                },
                err => {
                    if (err.response.data.error === expired) {
                        loginStore.dispatch({ type: 'LOGOUT' });
                    }
                    loader.dispatch({ type: 'LOADED' });
                    reject(err.response.data);
                }
            );
    }) 
}

export function getSlideById(id) {

    return new Promise((resolve, reject) => {
        loader.dispatch({ type: 'LOADING' });
        axios.get(`${API_URL}/admin/slides/get/${id}`)
            .then(
                res => {
                    loader.dispatch({ type: 'LOADED' });
                    resolve(res.data.data);
                },
                err => {
                    if (err.response.data.error === expired) {
                        loginStore.dispatch({ type: 'LOGOUT' });
                    }
                    loader.dispatch({ type: 'LOADED' });
                    reject(err.response.data);
                }
            );
    }) 
}

export function createSlide(data) {
    loader.dispatch({ type: 'LOADING' });
    let formData = new FormData();

    for (let k in data) {
        formData.append(k, data[k]);
    }

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/admin/slides/create`, formData)
            .then(
                res => {
                    loader.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    if (err.response.data.error === expired) {
                        loginStore.dispatch({ type: 'LOGOUT' });
                    }
                    loader.dispatch({ type: 'LOADED' });
                    reject(err.response.data);
                }
            );
    })
}

export function updateSlide(data) {
    loader.dispatch({ type: 'LOADING' });
    let formData = new FormData();

    for (let k in data) {
        formData.append(k, data[k]);
    }

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/admin/slides/update`, formData)
            .then(
                res => {
                    loader.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    loader.dispatch({ type: 'LOADED' });
                    reject(err);
                }
            );
    })
}

export function logout() {
    return dispatch => {
      localStorage.removeItem('AUTH');
      setAuthorizationToken(false);
      dispatch();
    }
  }
import React, { Component } from 'react';

class Loader extends Component {
    render() {

        return (
            <div className="loader">
                <div className="typing_loader"></div>
            </div>
        )
    }
}

export default Loader;
import { createStore } from 'redux';
import setAuthorizationToken from '../utils/setAuthorizationToken';

let initialState = {};

if (localStorage.AUTH) {
  initialState = JSON.parse(localStorage.getItem('AUTH'));
  setAuthorizationToken(initialState.token);
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      state = {
        isAuthenticated: true,
        token: action.token,
        user: action.payload
      }
      localStorage.setItem('AUTH', JSON.stringify(state));
      return state;
    
    case 'LOGOUT':
      state = {};
      localStorage.removeItem('AUTH');
      return state;

    default:
      return state;
  };
};

let loginStore = createStore(authentication);

export default loginStore;
import React, { Component } from 'react';

class Footer extends Component {

  render() {
    return (
        <footer className="footer">
            <ul className="list-inline">
              <li className="list-inline-item">2019 © Motonet</li>
              <li className="list-inline-item"><a href="mailto:fn.nelli@gmail.com">Get Help.</a></li>
            </ul>
        </footer>
      );
  };
}

export default Footer;

import React, { Component } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

class AppContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {

        return (
            <div>
                <Navbar />
                <Sidebar />
                <div id="wrapper">
                    <div className="main-content">
                        {this.props.children}
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

export default AppContainer;
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumericInput from 'react-numeric-input';
import Dropzone from 'react-dropzone';
import "react-table/react-table.css";
import swal from 'sweetalert';
import { createSlide } from '../services/slides';


class SlidesEdit extends Component {

    constructor(props) {
        super(props);

        this.state = { slide: { name: '', destination: '', start_date: '', end_date: '', desktop: '', mobile: '', active: false } };

        this.discard = this.discard.bind(this);
        this.submit = this.submit.bind(this);
        this.handleInputChanges = this.handleInputChanges.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.handlePriorityChange = this.handlePriorityChange.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.input = React.createRef();
    }

    async componentDidMount() {
        this.setState({ active: false, priority: 1, changes: false });
    }

    discard() {
        if (this.state.changes) {
            swal({   
                title: "Discard?",   
                text: "Are you sure you want to discard the changes you made?",   
                icon: "warning",  
                buttons: ['No, keep the changes.', 'Yes, delete them.'], 
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            }).then(
                (value) => {
                    if (value) {
                        this.props.history.push('/slides');
                    }
                }
            );
        } else {
            this.props.history.push('/slides');
        }
        
    }

    async submit(event) {
        event.preventDefault();

        let { slide, name, destination, start_date, end_date, priority, desktop, mobile, active } = this.state;

        if (!name || !destination || !start_date || !end_date || !priority) {
            this.setState({error: "Please, complete all the fields"});
            return;
        }

        if (destination.indexOf('http') !== 0) {
            this.setState({error: "Please, enter a valid URL."});
            return;
        }

        if (!desktop) {
            desktop = slide.desktop;
        }

        if (!mobile) {
            mobile = slide.mobile;
        }

        let newSlide = {
            name,
            destination,
            priority,
            start_date,
            end_date,
            mobile,
            desktop,
            active
        }

        try {
            const create = await createSlide(newSlide);
            create.data.start_date = new Date(create.data.start_date);
            create.data.end_date = new Date(create.data.end_date);

            let { name, destination, start_date, end_date, active, priority } = create.data;

            this.setState({ changes: false, slide: create.data, name, destination, start_date, end_date, desktop: null, mobile: null, active, priority, error: null });
            
            swal({   
                title: "Success",   
                text: "The changes are now live.",   
                icon: "success"
            }).then(
                (value) => {
                    this.props.history.push('/slides');
                }
            );

        } catch (e) {
            console.log(e);
            this.setState({error: "There is a problem. Please, try again later."})
        }
    }

    handleInputChanges(event) {
        let { id, value } = event.target;

        switch(id) {
            case 'name':
                this.setState({ name: value });
                break;
            case 'destination':
                this.setState({ destination: value });
                break;
            default:
                break;
        }

        this.setState({ changes: true });
    }

    handleStartChange(date) {
        this.setState({ 
            start_date: date,
            changes: true
        });
    }

    handleEndChange(date) {
        this.setState({ 
            end_date: date,
            changes: true
        });
    }

    handlePriorityChange(value) {
        this.setState({ 
            priority: value,
            changes: true
        });
    }

    handleActiveChange(evt) {
        let { value } = evt.target;

        this.setState({
            active: value === 'yes' ? true : false,
            changes: true
        })
    }

    render() {

        return (
            <div className="row small-spacing">
                <div className="col-lg-12 col-xs-12">
                    <div className="box-content card white">
                        <h4 className="box-title">New Slide</h4>
                        <div className="card-content">
                            <form onSubmit={this.submit}>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Name" ref={this.input} value={this.state.name} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="destination">Destination</label>
                                            <input type="text" className="form-control" id="destination" placeholder="Enter an URL" ref={this.input} value={this.state.destination} onChange={this.handleInputChanges} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="start_date">Start Date</label>
                                            <div className="form-control">
                                            <DatePicker
                                                id="start_date"
                                                className="datepick"
                                                selected={this.state.start_date}
                                                onChange={this.handleStartChange}
                                                showTimeSelect
                                                dateFormat="dd/MM/yyyy - HH:mm"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                timeCaption="time"
                                                placeholderText="Enter a Start Date"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="end_date">End Date</label>
                                            <div className="form-control">
                                            <DatePicker
                                                id="end_date"
                                                className="datepick"
                                                selected={this.state.end_date}
                                                onChange={this.handleEndChange}
                                                showTimeSelect
                                                dateFormat="dd/MM/yyyy - HH:mm"
                                                timeFormat="HH:mm"
                                                timeIntervals={30}
                                                timeCaption="time"
                                                placeholderText="Enter an End Date"
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="end_date">Mobile Image (600x350px)</label>
                                            <div className={ this.state.mobile ? 'form-control image-active' : 'form-control' }>
                                                <Dropzone
                                                multiple={false}
                                                accept="image/*"
                                                onDrop={(acceptedFiles) => {
                                                    const type = acceptedFiles[0].type;

                                                    if (type === 'image/jpeg' || type === 'image/png') {
                                                        const reader = new FileReader()

                                                        reader.onabort = () => console.log('file reading was aborted')
                                                        reader.onerror = () => console.log('file reading has failed')
                                                        reader.onload = async () => {
                                                            const binaryStr = reader.result;
                                                            this.setState({ mobile: binaryStr, changes: true });
                                                        }

                                                        reader.readAsDataURL(acceptedFiles[0]);
                                                    } else {
                                                        this.setState({ errorA: 'Please upload an image in PNG or JPEG format.' })
                                                        console.log(this.state.errorA);
                                                    }

                                                    
                                                }}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                { this.state.mobile
                                                                    ? <img src={this.state.mobile} className="img-file uploaded" alt="mobile uploaded" />
                                                                    : <p>Click to Upload Image</p>
                                                                }
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className={ this.state.desktop ? 'form-group has-warning' : 'form-group' }>
                                            <label htmlFor="end_date">Desktop Image (1600x340px)</label>
                                            <div className={ this.state.desktop ? 'form-control image-active' : 'form-control' }>
                                                <Dropzone
                                                multiple={false}
                                                accept="image/*"
                                                onDrop={(acceptedFiles) => {
                                                    const type = acceptedFiles[0].type;

                                                    if (type === 'image/jpeg' || type === 'image/png') {
                                                        const reader = new FileReader()

                                                        reader.onabort = () => console.log('file reading was aborted')
                                                        reader.onerror = () => console.log('file reading has failed')
                                                        reader.onload = async () => {
                                                            const binaryStr = reader.result;
                                                            this.setState({ desktop: binaryStr, changes: true });
                                                        }

                                                        reader.readAsDataURL(acceptedFiles[0]);
                                                    } else {
                                                        this.setState({ error: 'Please upload an image in PNG or JPEG format.' })
                                                    }

                                                    
                                                }}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                { this.state.desktop
                                                                    ? <img src={this.state.desktop} className="img-file uploaded" alt="desktop - uploaded" />
                                                                    : <p>Click to Upload Image</p>
                                                                }
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="priority">Priority</label>
                                            <NumericInput 
                                                className="form-control" 
                                                value={ this.state.priority } 
                                                min={ 1 } 
                                                max={ 100 } 
                                                step={ 1 } 
                                                precision={ 0 } 
                                                mobile
                                                inputMode="numeric"
                                                onChange={this.handlePriorityChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                        <div className='form-group'>
                                            <label htmlFor="active">Active</label>
                                            <select id="active" className="form-control" onChange={this.handleActiveChange} value={this.state.active ? 'yes' : 'no'}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 margin-top-20">
                                        <p className="danger">{this.state.error}</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                        <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={this.discard}>Discard</button>
                                        <button type="submit" className="btn btn-primary btn-md" disabled={!this.state.changes}>Save Changes</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
  };
}

export default SlidesEdit;

import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import { API_URL } from '../utils/config';
import loginStore from '../reducers/auth';

export function login(email, password) {

    let data = { email, password };

    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/admin/login`, data)
            .then(
                res => {
                    const token = res.data.token;
                    const payload = res.data.result;
                    const action = {
                        type: "LOGIN",
                        token,
                        payload
                    };

                    loginStore.dispatch(action);
                    setAuthorizationToken(token);

                    resolve(res.data);
                },
                err => {
                    reject(err.response.data);
                }
            );
    });
};

export function validate() {
    axios.get(`${API_URL}/admin/validate`)
        .then(
            res => {
                if (!res.data.email) {
                    const action = {
                        type: "LOGOUT"
                    };
                    setAuthorizationToken(false);
                    loginStore.dispatch(action);
                }
            },
            err => {
                const action = {
                    type: "LOGOUT"
                };
                setAuthorizationToken(false);
                loginStore.dispatch(action);
            }
        );
};

export function logout() {
    return dispatch => {
        localStorage.removeItem('AUTH');
        setAuthorizationToken(false);
        dispatch();
    }
};
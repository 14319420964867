import React, { Component } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import Carts from './components/Carts';
import Clients from './components/Clients';
import ContactInfo from './components/ContactInfo';
import Credicuotas from './components/Credicuotas';
import Dashboard from './components/Dashboard';
import Insurance from './components/Insurance';
import Notifications from './components/Notifications';
import NotificationsNew from './components/Notifications.New';
import Payments from './components/Payments';
import Search from './components/Search';
import Slides from './components/Slides';
import SlidesEdit from './components/Slides.Edit';
import SlidesNew from './components/Slides.New';
import Subscribers from './components/Subscribers';
import Status from './components/Status';
import Loader from './components/Loader';
import Login from './components/Login';
import AppContainer from './components/AppContainer';
import { 
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';

import loginStore from './reducers/auth';
import loader from './reducers/loading';
import { validate } from './services/auth';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = loginStore.getState();

    loginStore.subscribe(() => {
      this.setState({ isAuthenticated: loginStore.getState().isAuthenticated ? true : false })
    });

    loader.subscribe(() => {
      this.setState({ loading: loader.getState().loading ? true : false });
    })
  };

  componentDidMount() {
    if (this.state.isAuthenticated) {
      setTimeout(() => { validate() }, 1000);
    }
  }

  render() {
    return(
      <Router>
        <CSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={100}
          transitionLeaveTimeout={300}>
            { this.state.loading ?  <Loader /> : null }
         </CSSTransitionGroup>
        <div>
          
          { this.state.isAuthenticated
          ? (
            <AppContainer>
              <Route path='/' exact component={Dashboard} />
              <Route path='/carts' exact component={Carts} />
              <Route path='/clients' exact component={Clients} />
              <Route path='/contact-info' exact component={ContactInfo} />
              <Route path='/credicuotas' exact component={Credicuotas} />
              <Route path='/insurance' exact component={Insurance} />
              <Route path='/notifications' exact component={Notifications} />
              <Route path='/notifications/new' exact component={NotificationsNew} />
              <Route path='/payments' exact component={Payments} />
              <Route path='/searches' exact component={Search} />
              <Route path='/slides' exact component={Slides} />
              <Route path='/slides/new' component={SlidesNew} />
              <Route path='/slides/edit/:id' component={SlidesEdit} />
              <Route path='/subscribers' exact component={Subscribers} />
              <Route path='/status' exact component={Status} />
            </AppContainer>
          )
          : <Redirect to='/login' />}

          <Route path='/login' component={Login} />
        </div>
      </Router>
    );
  };
};

export default App;

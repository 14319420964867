import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getCredicuotas, cancel } from '../services/credicuotas';

class Credicuotas extends Component {

    _isMounted = true;

    constructor(props) {
        super(props);

        this.state = { users: [], page: 1, pages: 1, show: 10, loading: false, exporting: false };
        this.export = this.export.bind(this);
    }

    async export() {
        if (this._isMounted) {
            this.setState({exporting: true});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        cancel();
    }

    render() {

        return (
            <section>
                <div className='row'>
                    <div className='col-md-6 col-xs-12 align-self-center'>
                        <h3>Credicuotas</h3>
                    </div>
                    <div className='col-md-6 text-right'>
                        {/*<button onClick={this.export} className="btn btn-icon btn-icon-left btn-success"><i className="ico fa fa-save" /> Export Users</button>*/}
                    </div>
                </div>
                <hr />
                <ReactTable
                    data={this.state.requests}
                    columns={[
                        {
                            Header: <span>First Name <i className="fas fa-sort" /></span>,
                            accessor: 'fname',
                            filterable: true
                        },
                        {
                            Header: <span>Last Name <i className="fas fa-sort" /></span>,
                            accessor: 'lname',
                            filterable: true
                        },
                        {
                            id: 'dni',
                            Header: <span>National ID <i className="fas fa-sort" /></span>,
                            accessor: (d) => d.dni,
                            width: 120,
                            sortable: true,
                            filterable: false
                        },
                        {
                            id: 'maxOffer',
                            Header: <span>Max Offer <i className="fas fa-sort" /></span>,
                            accessor: (d) => d.maxOffer ? d.maxOffer : 0,
                            filterable: false,
                            width: 100
                        },
                        {
                            id: 'actions',
                            Header: 'Actions',
                            accessor: d => 
                            <span>{d.pilotId ? <a href={`https://app.pilotsolution.com.ar/controlpanel2/index.php?guid=${d.pilotId}`} target="_blank" rel="noopener noreferrer" className="btn-sm btn-icon btn-icon-left btn-primary"><i className="fas fa-id-card-alt" />  Go to CRM</a> : null }{/*<NavLink to={"/users/" + d._id } className="btn-sm btn-icon btn-icon-left btn-warning"><i className="ico fas fa-plus" /> View</NavLink>*/}</span>,
                            resizable: false,
                            width: 200,
                            sortable: false
                        }
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={this.state.show}
                    loading={this.state.loading}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageJump={true}
                    resizable={true}
                    sortable={true}
                    manual
                    onFetchData={
                        async (state, instance) => {
                            this.setState({loading: true});

                            let options = {};

                            if (state) {
                                options = {
                                    sort: state.sorted.length > 0 ? state.sorted[0].id : 'start_date',
                                    direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
                                    filters: state.filtered.length > 0 ? state.filtered : [],
                                    results: state.pageSize,
                                    offset: (state.page +1) * state.pageSize - state.pageSize
                                };
                            } else {
                                options = {
                                    sort: 'start_date',
                                    direction: 'desc',
                                    filters: [],
                                    results: 10,
                                    offset: 0
                                }
                            }
                            

                            try {
                                let data = await getCredicuotas(options);
                                if (this._isMounted) {
                                    this.setState({requests: data.requests, loading: false, pages: data.pages, show: data.results, lastUpdate: data.last_update});
                                }
                            } catch (e) {

                            }
                        }
                    }
                    onSortedChange={
                        (newSorted, column, shiftKey) => {
                            cancel();
                        }
                    }
                    onFilteredChange={
                        (filtered, column) => {
                            cancel();
                        }
                    }
                    className="-striped -highlight"
                />
            </section>
        );
  };
}

export default Credicuotas;

import React, { Component } from 'react';
import loginStore from '../reducers/auth';
import { login } from '../services/auth';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = loginStore.getState();
    };

    render() {

        const submit = async (event) => {
            event.preventDefault();
    
            let email = this.refs.email.value;
            let password = this.refs.password.value;
    
            if (!email || !password) {
                return this.setState({ error: 'empty' });
            }

            try {
                let loginResult = await login(email, password);
    
                if (loginResult.token) {
                    this.props.history.push('/');
                }
            }
            catch (e) {
                this.setState({ error: e.status });
            }
        }

        if (this.state.token) {
            this.props.history.push('/');
            return null
        }
        
        return (
            <div id="single-wrapper">
                <form onSubmit={submit} className="frm-single">
                    <div className="inside">
                        <div className="title"><strong>Motonet</strong> EC Admin</div>
                        <div className="frm-title">Login</div>
                        <div className="frm-input">
                            <input type="email" placeholder="Email" className="frm-inp" ref="email" />
                            <i className="fa fa-user frm-ico" />
                        </div>
                        <div className="frm-input">
                            <input type="password" placeholder="Password" className="frm-inp" ref="password" />
                            <i className="fa fa-lock frm-ico" />
                        </div>
                        <div className="clearfix margin-bottom-20"></div>
                        { this.state.error === "empty" ? <p className="danger">Please, enter your credentials.</p> : '' }
                        { this.state.error === 401 ? <p className="danger">The password is not correct.</p> : '' }
                        { this.state.error === 404 ? <p className="danger">No user found for that email.</p> : '' }
                        { this.state.error === 500 ? <p className="danger">Server error. Please, try again later.</p> : '' }
                        <button type="submit" className="frm-submit">Login<i className="fa fa-arrow-circle-right" /></button>
                        <div className="frm-footer">ReachOut © 2020.</div>
                        {/* /.footer */}
                    </div>
                    {/* .inside */}
                </form>
                {/* /.frm-single */}
            </div>
        );
    }
}

export default Login;
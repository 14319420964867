import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import Modal from 'react-modal';
import "react-table/react-table.css";
import { getSlides, getSlideById } from '../services/slides';

Modal.setAppElement(document.getElementById('root'))

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

class Slides extends Component {

    constructor(props) {
        super(props);

        this.state = { slides: [], page: 1, pages: 1, show: 10, loading: false, modalIsOpen: false, image: '', detailedContent: {} };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async openModal(event) {
        let data = event.target.id.split('|');
        console.log(data);

        let id = data[0];

        let promo = await getSlideById(id);
        this.setState({modalIsOpen: true, detailedContent: promo[data[1]]});
    }

    closeModal() {
        this.setState({modalIsOpen: false, image: ''});
    }

    render() {

        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'><h3>Slides</h3></div>
                    <div className='col-md-6 text-right'><NavLink to='/slides/new' className="btn btn-success">Create Slide</NavLink></div>
                </div>
                <hr />
                <ReactTable
                    data={this.state.slides}
                    columns={[
                        {
                            Header: "Name",
                            accessor: 'name',
                            width: 200,
                        },
                        {
                            id: 'mobile',
                            Header: "Mobile",
                            accessor: (d) => <span id={d._id + '|mobile'} className='btn-sm btn-warning btn-icon btn-icon-left' onClick={this.openModal}><i className="ico fa fa-image" /> View</span>,
                            width: 100,
                        },
                        {
                            id: 'desktop',
                            Header: "Desktop",
                            accessor: (d) => <span id={d._id + '|desktop'} className='btn-sm btn-warning btn-icon btn-icon-left' onClick={this.openModal}><i className="ico fa fa-image" /> View</span>,
                            width: 100,
                        },
                        {
                            id: "start_date",
                            Header: "Start Date",
                            accessor: (d) => new Date(d.start_date).toLocaleString(),
                            width: 200
                        },
                        {
                            id: "end_date",
                            Header: "End Date",
                            accessor: (d) => new Date(d.end_date).toLocaleString(),
                            width: 200
                        },
                        {
                            Header: "Priority",
                            accessor: 'priority',
                            width: 100
                        },
                        {
                            id: 'active',
                            Header: "Active",
                            accessor: (d) => d.active ? 'Yes' : 'No',
                            width: 100
                        },
                        {
                            Header: 'Actions',
                            accessor: '_id',
                            Cell: props => <NavLink className="btn-sm btn-icon btn-icon-left btn-primary" to={"/slides/edit/" + props.value }><i className="ico fa fa-edit" /> Edit</NavLink>,
                            resizable: false
                        }
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={this.state.show}
                    loading={this.state.loading}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageJump={true}
                    resizable={true}
                    sortable={false}
                    pageSizeOptions={[5, 10, 20, 25]}
                    manual
                    onFetchData={
                        async (state, instance) => {
                            this.setState({loading: true});
                            let slides = await getSlides(state.pageSize, (state.page +1) * state.pageSize - state.pageSize);
                            this.setState({slides: slides.slides, loading: false, pages: slides.pages, show: slides.results});
                        }
                    }
                className="-striped -highlight"
                />
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                    <img src={this.state.detailedContent} className="modal-content-image" alt="Slide" />
                </Modal>
            </div>
        );
  };
}

export default Slides;

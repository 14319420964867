import axios from 'axios';
import { API_URL } from '../utils/config';
import loginStore from '../reducers/auth';
const expired = 'TokenExpiredError';

export const CancelToken = axios.CancelToken;
export let cancel;

export const analytics = async (options) => {
    try {
        let res = await axios.post(`${API_URL}/admin/search/analytics`, options,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        if (res.data.dataset) {
            return res.data;
        } else {
            throw res.data.message;
        }
        
    } catch (err) {
        if (err) {
            if (err.response) {
                if (err.response.data.error === expired) {
                    loginStore.dispatch({ type: 'LOGOUT' });
                }
                throw err.response.status;
            }
        }
        
        throw err;
    }
};
import axios from 'axios';
import { API_URL } from '../utils/config';
import loginStore from '../reducers/auth';
import loader from '../reducers/loading';

const expired = 'TokenExpiredError';

export function getSubscribers(results, offset) {

    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/admin/subscribe/list?results=${results}&offset=${offset}`)
            .then(
                res => {
                    resolve(res.data);
                },
                err => {
                    if (err.response.data.error === expired) {
                        loginStore.dispatch({ type: 'LOGOUT' });
                    }
                    loader.dispatch({ type: 'LOADED' });
                    reject(err.response.data);
                }
            );
    }) 
}

export function exportSubscribers() {

    const email = loginStore.getState().user.email;

    return new Promise((resolve, reject) => {
        const data = { email };

        loader.dispatch({ type: 'LOADING' });
        axios.post(`${API_URL}/admin/subscribe/export`, data)
            .then(
                res => {
                    loader.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    if (err.response.data.error === expired) {
                        loginStore.dispatch({ type: 'LOGOUT' });
                    }
                    loader.dispatch({ type: 'LOADED' });
                    reject(err.response.data);
                }
            );
    }) 
};